.wrapper {
  margin-bottom: 5px;
  :global {
    .tabs-image {
      .ant-tabs-tab {
        padding-top: 0;
      }
      .ant-tabs-nav {
        margin-bottom: 5px;
      }
    }
  }
}
