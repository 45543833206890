body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

:root {
  --primaryColor: #dc7b62;
  --antd-wave-shadow-color: #dc7b62;
}

*::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

*::-webkit-scrollbar-track {
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0);
  background-color: #fff;
  border-radius: 4px;
  height: 2px;
}

*::-webkit-scrollbar-thumb {
  height: 2px;
  border-radius: 4px;
  box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.775);
  background: rgba(162, 161, 161, 0);
}
.content-form {
  &.is-preview {
    padding: .7cm 2cm !important;
  }
}

.nowrap {
  white-space: nowrap;
}

@media print {
  * {
    line-height: 1.2em;
  }
  .Header {
    display: none;
  }
  .header-print {
    padding: 0 !important;
  }
  .header-print > *,
  .hidden-print > *,
  .is-deleted {
    display: none !important;
  }
  .content-form {
    border: 0 !important;
  }
  .content-preview {
    padding-top: 0 !important;
  }
}

@page {
  size: auto;
  margin: 0mm;
}

.flex {
  display: flex;
  &.justify-content-between {
    justify-content: space-between;
  }
  &.justify-content-center {
    justify-content: center;
  }
  &.justify-content-flex-end {
    justify-content: flex-end;
  }
  &.align-items-center {
    align-items: center;
  }
  .flex-1 {
    flex: 1;
  }
}

.pointer {
  cursor: pointer;
}

.text-right {
  text-align: right;
}

.uppercase {
  text-transform: uppercase;
}

.mt-1 {
  margin-top: 0.5em;
}
.mt-2 {
  margin-top: 1em;
}
.mb-0 {
  margin-bottom: 0;
}
.mb-2 {
  margin-bottom: 1em;
}
.mr-1 {
  margin-right: 0.5em;
}
.mr-2 {
  margin-right: 1em;
}
.ml-1 {
  margin-left: 0.5em;
}
.ml-2 {
  margin-left: 1em;
}
.my-2 {
  margin-top: 1em;
  margin-bottom: 1em;
}

.btn {
  outline: none;
  border: 0;
  border-radius: 5px;
  padding: 5px 15px;
  color: #fff;
  cursor: pointer;
  &.btn-success {
    background-color: #579a23;
  }
  &.btn-warning {
    background-color: #f4a800;
  }
  &.btn-primary {
    background-color: var(--primaryColor);
  }
}

.ant-btn {
  &:not(.ant-btn-primary) {
    &:hover,
    &:focus {
      color: var(--primaryColor);
      border-color: var(--primaryColor);
    }
  }

  &.ant-btn-primary {
    background-color: var(--primaryColor);
    border-color: var(--primaryColor);
    &:focus {
      background-color: #b05f49;
      border-color: #b05f49;
    }
  }
  &.ant-btn-success {
    background-color: #73A255;
    border-color: #73A255;
    color: #fff !important;
    &:focus, &:hover {
      background-color: #73A255;
      border-color: #73A255;
    }
  }
}

.ant-input {
  &:hover {
    border-color: var(--primaryColor);
  }
  &:focus {
    box-shadow: 0 0 0 2px #f7e8e2 !important;
    border-color: var(--primaryColor);
  }
}

.ant-input-number,
.ant-input-affix-wrapper,
.ant-picker {
  &:hover {
    border-color: var(--primaryColor);
  }
  &:focus {
    border-color: var(--primaryColor);
  }
  &-focused {
    box-shadow: 0 0 0 2px #f7e8e2;
    border-color: var(--primaryColor);
  }
}

.ant-select {
  &:hover {
    .ant-select-selector {
      border-color: var(--primaryColor) !important;
    }
  }
  &:focus {
    border-color: var(--primaryColor);
  }
  &-focused {
    .ant-select-selector {
      box-shadow: 0 0 0 2px #f7e8e2 !important;
      border-color: var(--primaryColor) !important;
    }
  }
}

.ant-pagination-item {
  &:hover {
    border-color: var(--primaryColor) !important;
    a {
      color: var(--primaryColor) !important;
    }
  }
}

.ant-pagination-next,
.ant-pagination-prev {
  &:hover {
    button:not(:disabled) {
      border-color: var(--primaryColor) !important;
      color: var(--primaryColor) !important;
    }
  }
}

.ant-pagination-item-active {
  border-color: var(--primaryColor) !important;
  a {
    color: var(--primaryColor) !important;
  }
}

.ant-form-item {
  &:not(.ant-form-item-has-error) {
    .ant-form-item-control-input {
      &:hover {
        .ant-input,
        .ant-input-affix-wrapper,
        .ant-select-selector {
          border-color: var(--primaryColor) !important;
        }
      }
      .ant-input {
        &:focus {
          box-shadow: 0 0 0 2px #f7e8e2;
        }
      }
    }
  }
}

.ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  color: rgba(0, 0, 0, 0.85);
  font-weight: 600;
  background-color: #f7e8e2;
}

.ant-picker-week-panel-row-selected td {
  background-color: var(--primaryColor) !important;
  &:first-child {
    border-top-left-radius: 2px;
    border-bottom-left-radius: 2px;
  }
  &:last-child {
    border-top-right-radius: 2px;
    border-bottom-right-radius: 2px;
  }
}

.ant-picker-cell-today {
  .ant-picker-cell-inner:before {
    border-color: var(--primaryColor) !important;
  }
  &.ant-picker-cell-selected,
  &.ant-picker-cell-range-start,
  &.ant-picker-cell-range-end {
    .ant-picker-cell-inner:before {
      border: none;
    }
  }
}

.ant-picker-cell-selected,
.ant-picker-cell-range-start,
.ant-picker-cell-range-end {
  .ant-picker-cell-inner {
    background-color: var(--primaryColor) !important;
    transform: scale(1.1) !important;
    border-color: unset;
  }
}

.ant-picker-cell-range-start,
.ant-picker-cell-range-end,
.ant-picker-cell-in-range {
  &::before {
    background-color: #f7e8e2 !important;
  }
}

.ant-picker-range .ant-picker-active-bar {
  background-color: var(--primaryColor);
}

tr.drop-over-downward td {
  border-bottom: 2px dashed #1890ff;
}

tr.drop-over-upward td {
  border-top: 2px dashed #1890ff;
}

tr.is-done td {
  background-color: #e8f6e3;
  button {
    opacity: 0.6;
  }
}

.ant-select-disabled {
  &:hover {
    .ant-select-selector {
      border: 1px solid #d9d9d9 !important;
    }
  }
}

button:disabled {
  &:hover {
    border: 1px solid #d9d9d9 !important;
    color: rgba(0, 0, 0, 0.25) !important;
  }
}

.icon-service-type {
  width: 0;
  height: 0;
  border-top: 10px solid transparent;
  border-bottom: 10px solid transparent;
  transform: rotate(45deg);
  display: inline-block;
  margin-right: 10px;
  &.type-10 {
    border-right: 10px solid var(--primaryColor);
  }
  &.type-20 {
    border-right: 10px solid #73a255;
  }
}

button:disabled {
  background-color: #f5f5f5 !important;
  border-color: #d9d9d9 !important;
}
