.wrapper {
  :global {
    .header {
      .tabs {
        border-bottom: 1px solid #c1c1c1;
        .tab-item {
          font-size: 18px;
          display: inline-block;
          height: 40px;
          line-height: 40px;
          padding: 0 15px;
          border-top-left-radius: 5px;
          border-top-right-radius: 5px;
          &.active {
            background-color: var(--primaryColor);
            color: #fff;
          }
        }
      }
    }

    .main {
      .left {
        img {
          width: 300px;
          border: 2px solid var(--primaryColor);
        }
      }
      .right {
        padding-left: 50px;
        padding-bottom: 50px;
        table {
          width: 100%;
          tbody {
            tr {
              vertical-align: top;
              margin-top: 2em;
              td:first-child {
                font-size: 15px;
                font-weight: 600;
                width: 250px;
              }
              input {
                &:not(.ant-input-number-input) {
                  margin-bottom: 1em;
                }
              }
            }
          }
        }
      }
      .pt-1 {
        padding-top: 1em;
      }
    }

    .working-shifts-table {
      .ant-switch-checked {
        background-color: #579a23;
      }
    }
  }
}
