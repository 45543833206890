.wrapper {
  min-height: calc(100vh - 67px);
  :global {
    .option-update {
      padding: 10px 0 15px 0;
      border-bottom: 2px solid #e7c2ba;
      border-top: 2px solid #e7c2ba;
    }
    .title-item {
      display: flex;
      margin-bottom: 5px;
      overflow-x: hidden;
      &:first-child {
        position: sticky;
        top: 105px;
        z-index: 9;
        .title-item-label {
          min-width: 150px;
          min-height: unset;
          text-align: center;
          background: #fff;
          border: 1px dashed #ccc;
          justify-content: center;
          font-size: 18px;
          font-weight: 500;
          color: #333;
        }
        .title-item-skips {
          .skip-item {
            text-align: center;
          }
        }
      }
      .title-item-label {
        background-color: var(--primaryColor);
        text-align: left;
        border-radius: 3px;
        margin-right: 5px;
        display: flex;
        align-items: center;
        color: #fff;
        &__left {
          width: 150px;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          background-color: var(--primaryColor);
          height: 100%;
          span {
            &:first-child {
              font-weight: 500;
              font-size: 15px;
              padding: 10px 0;
            }
          }
        }
        &__right {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          .service-type {
            width: 50%;
            padding-left: 24px;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 600;
            &.type-1 {
              border-bottom: 0.5px solid #dc7b62;
            }
          }
        }
        &.active {
          &__left {
            color: #fff;
          }
        }
      }
      .title-item-skips {
        display: flex;
        flex: 1 1 auto;
        width: calc(100% - 105px);
        justify-content: space-between;
        .skip-item {
          background-color: #f8f8f8;
          width: inherit;
          border-radius: 3px;
          margin-left: 10px;
          display: flex;
          justify-content: space-between;
          transition: all .15s ease-in-out;
          .service-type-shifts  {
            width: 100%;
            background-color: #dadada;
            padding: 5px;
            display: flex;
            flex-direction: column;
            margin-bottom: 1px;
            position: relative;
            &.active {
              background-color: #579a23;
              cursor: pointer;
            }
            &.old {
              background-color: #a09b96;
              cursor: not-allowed;
            }
            .delete-working-shift {
              position: absolute;
              right: 10px;
            }
          }
          &.is-update {
            cursor: pointer;
          }
          &.skip-item-bg {
            justify-content: center;
            color: #fff;
            flex-direction: column;
            .title-skip {
              background-color: var(--primaryColor);
              padding: 15px 0;
              &.disabled {
                background-color: #a09b96;
              }
              b {
                font-weight: 700;
                font-size: 18px;
              }
            }
          }
          &.skip-item-shifts {
            justify-content: flex-start;
            color: #fff;
            flex-direction: column;
            .title-skip {
              background-color: var(--primaryColor);
              padding: 15px 0;
              b {
                font-weight: 700;
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}

.timePicker {
  :global {
    .ant-picker-footer {
      display: none;
    }
  }
}

.dropdownSelect {
  :global {
    padding: 0;
  }
}