.wrapper {
  border: 2px solid #e7c2ba;
  :global {
    .tabs-skip {
      display: flex;
      background-color: #f7e8e2;
      font-weight: bold;
      .tab-skip-item {
        width: (100%/15);
        min-width: 130px;
        color: #8f8f8f;
        cursor: pointer;
        text-align: center;
        padding: 10px 15px;
        &:first-child {
          text-align: left;
        }
        &.active {
          background-color: #fff;
          color: var(--primaryColor);
          font-weight: bold;
        }
      }
    }
    .header {
      .wrapper-steps-time {
        display: flex;
        overflow-x: auto;
        .item-sub-table, .item-step-time {
          .detail-type {
            background-color: #F1ECEC;
            padding-left: 8px;
            height: 0;
            overflow-y: hidden;
            transition: height .15s ease-in-out;
            margin: 5px 0;
            &.is-show {
              height: 120px;
            }
          }
        }
        .item-step-time {
          padding-top: 10px;
          position: relative;
          text-align: left;
          cursor: pointer;
          min-width: 150px;
          padding-bottom: 1em;
          &:first-child {
            text-align: left;
            padding-left: 15px;
            min-width: 300px;
          }
          &.active {
            background-color: #f7e8e2;
            .icon-close {
              display: block;
              position: absolute;
              right: 5px;
              font-weight: bold;
            }
            .detail-type {
              background-color: #f7e8e2 !important;
            }
          }
          .icon-close {
            display: none;
          }
          .btn-add-time-skip {
            padding: 0 5px;
            border-radius: 2px;
            margin-left: 3px;
            background-color: #8AAE73;
            color: #fff;
            font-weight: bold;
            &.invalid {
              opacity: .3;
              cursor: not-allowed;
            }
          }
          .item-sub-table {
            min-height: 24px;
            text-align: left;
            .total-booking {
              width: 40px;
              display: inline-block;
            }
            .col-doctor {
              display: flex;
              align-items: center;
              .wrapper-icon-collapse {
                background-color: #C2C9D1;
                display: inline-flex;
                font-size: 14px;
                width: 16px;
                height: 16px;
                border-radius: 3px;
                cursor: pointer;
                align-items: center;
                justify-content: center;
                span {
                  color: #fff;
                  font-size: 11px;
                }
              }
            }
          }
        }
      }
    }
    .ant-dropdown-trigger {
      margin-bottom: 15px;
    }
    span.hr {
      width: 2px;
      height: 15px;
      background: #ccc;
      display: inline-block;
      transform: translateY(3px);
      margin-right: 8px;
      margin-left: 5px;
    }
    .anticon-edit {
      &.disabled {
        cursor: not-allowed;
        opacity: 0.5;
      }
    }
    .td-order {
      width: 70px;
    }
    .td-priority {
      width: 100px;
    }
    .td-user {
      width: 150px;
      padding-right: 0 !important;
      white-space: nowrap;
      text-overflow: ellipsis;
      overflow: hidden;
    }
    .td-weeks {
      padding-left: 0;
      width: 75px;
    }
    .td-type {
      width: 60px;
    }
    .td-service {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
    .td-room {
      width: 75px;
    }
    .td-date-time {
      width: 100px;
    }
    .td-checked-time {
      width: 82px;
    }
    .td-status {
      width: 130px;
    }
    .td-result {
      width: 140px;
    }
    .td-action {
      width: 170px;
      max-width: 170px;
      .btn-print {
        margin-bottom: 5px;
        flex-basis: 100%;
      }
    }
    .disabled-edit-field {
      opacity: 0;
      cursor: default;
    }
  }
}

.tooltip {
  z-index: 1;
}

.dropdown {
  :global {
    .ant-modal-body {
      padding-top: 30px;
      text-align: center;
      padding-bottom: 40px;
      span.anticon-warning {
        color: red;
        font-size: 25px;
        margin-right: 5px;
      }
    }
  }
}

.modalDetailSkips {
  :global {
    .ant-modal {
      width: 850px !important;
    }
    .list-bookings-detail {
      margin-top: 5px;
      table,
      td,
      th {
        border: 1px solid #c4c4c4;
      }
      td,
      th {
        padding: 5px 10px;
      }
      table {
        width: 100%;
        border-collapse: collapse;
      }
      th {
        background-color: #c1c1c180;
      }
      ul {
        padding-left: 15px;
        margin-bottom: 0;
      }
      .wrapper-status {
        border: none !important;
        justify-content: flex-start;
      }
    }
  }
}

.modalDoctorRoom {
  :global {
    td,
    th {
      padding: 5px 10px;
    }
    table {
      width: 100%;
      border-collapse: collapse;
    }
  }
}
