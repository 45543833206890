.container {
  :global {
    .wrapper-title {
      display: flex;
      margin-bottom: 20px;
      justify-content: center;
      align-items: center;
      .title-text {
        font-size: 24px;
        color: var(--primaryColor);
        text-transform: uppercase;
        font-weight: bold;
      }
      .ant-picker {
        height: 32px;
        input {
          ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
            color: #fff;
            opacity: 1; /* Firefox */
          }
          
          :-ms-input-placeholder { /* Internet Explorer 10-11 */
            color: #fff;
          }
          
          ::-ms-input-placeholder { /* Microsoft Edge */
            color: #fff;
          }
        }
      }
      .picker-date-day, .select-date-time {
        width: 150px;
      }
      .picker-date-day {
        background-color: #F8E5E0;
        border: 0;
        &.ant-picker-focused {
          box-shadow: none;
        }
        &.ant-picker-disabled {
          background-color: rgba(196, 196, 196, 0.7);
        }
      }
      .select-date-time {
        .ant-select-selector {
          background-color: #F8E5E0;
          border: 0 !important;
          box-shadow: none !important;
        }
        &.ant-select-disabled {
          .ant-select-selector  {
            background-color: rgba(196, 196, 196, 0.7) !important;
          }
        }
      }
    }

    .input-user-name,
    .input-phone-number {
      .ant-form-item-control-input-content,
      .ant-form-item-control {
        height: 32px;
      }

      .ant-form-item-has-error {
        .ant-select-focused {
          .ant-select-selector {
            outline: 0;
            box-shadow: 0 0 0 2px rgba(255, 77, 79, 0.2);
          }
        }
      }
    }

    .ant-form-item-label {
      line-height: unset;
    }

    .ant-calendar-picker {
      width: 100%;
    }

    .group-action {
      display: flex;
      justify-content: flex-end;

      .submit-button {
        margin-left: 10px;
      }
    }
    .wrapper-result-content {
      background-color: #F8E5E0;
      border-radius: 3px;
      padding: 1em 0;
      #result-content {
        width: 80%;
        margin: auto;
      }
    }
  }
}

.datePicker {
  z-index: 9999 !important;
}
