.wrapper {
  padding-top: 4px;
  :global {
    .ck-toolbar {
      width: 210mm !important;
      margin-left: auto !important;
      margin-right: auto !important;
      .ck-toolbar_vertical {
        width: 70px !important;
      }
    }
    .ck-content {
      width: 210mm;
      min-height: 500px;
      margin-left: auto;
      margin-right: auto;
      figure.image {
        max-width: 100%;
      }
      figure.table  {
        width: 100%;
        table {
          border: none;
          td {
            border: none;
          }
        }
      }
      .marker-green {
        background-color: #fff;
        color: #dc7b62;
      }
    }
    .ck-source-editing-area {
      textarea {
        width: 210mm !important;
      }
    }
    .pr-2 {
      padding-right: 10px;
    }
  }
}
