.wrapper {
  min-height: calc(100vh - 67px);
  :global {
    .option-update {
      padding: 10px 0 15px 0;
      border-bottom: 2px solid #e7c2ba;
      border-top: 2px solid #e7c2ba;
    }
    .title-item {
      display: flex;
      margin-bottom: 5px;
      overflow-x: hidden;
      &:first-child {
        .title-item-label {
          min-width: 100px;
          min-height: unset;
          text-align: center;
        }
        .title-item-skips {
          .skip-item {
            text-align: center;
          }
        }
      }
      .title-item-label {
        background-color: var(--primaryColor);
        text-align: left;
        border-radius: 3px;
        margin-right: 5px;
        display: flex;
        align-items: center;
        color: #fff;
        &__left {
          width: 100px;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: center;
          background-color: var(--primaryColor);
          height: 100%;
          span {
            &:first-child {
              font-weight: 700;
              font-size: 18px;
            }
          }
        }
        &__right {
          height: 100%;
          width: 100%;
          display: flex;
          flex-direction: column;
          .service-type {
            width: 50%;
            padding-left: 24px;
            display: flex;
            align-items: center;
            font-size: 16px;
            font-weight: 600;
            &.type-1 {
              border-bottom: 0.5px solid #dc7b62;
            }
          }
        }
        &.active {
          &__left {
            color: #fff;
          }
        }
      }
      .title-item-skips {
        display: flex;
        flex: 1 1 auto;
        width: calc(100% - 105px);
        justify-content: space-between;
        .skip-item {
          background-color: #f8f8f8;
          width: inherit;
          border-radius: 3px;
          margin-left: 10px;
          display: flex;
          justify-content: space-between;
          transition: all .15s ease-in-out;
          .service-type-doctors  {
            width: calc(50% - 4px);
            background-color: #dadada;
            padding: 5px;
            display: flex;
            .ant-select {
              cursor: pointer;
              &.is-empty {
                .ant-select-selector {
                  background-color: #fff !important;
                  height: 40px;
                  margin-bottom: 0px;
                  line-height: 40px;
                  background: #fff;
                  border-radius: 2px;
                }
              }
              .ant-select-selector {
                background: transparent;
                padding: 0;
                border: 0 !important;
                box-shadow: none !important;
                &::after {
                  content: none;
                }
                .ant-select-selection-item {
                  cursor: pointer;
                  width: 100%;
                  height: 40px;
                  line-height: 40px;
                  background-color: #fff;
                  display: flex;
                  justify-content: space-between;
                  margin: 0;
                  margin-bottom: 3px;
                  border: 0;
                }
                .ant-select-selection-search {
                  display: none;
                }
              }
            }
          }
          &.is-update {
            cursor: pointer;
          }
          &.skip-item-bg {
            justify-content: center;
            color: #fff;
            flex-direction: column;
            .title-skip {
              background-color: var(--primaryColor);
              padding: 15px 0;
              b {
                font-weight: 700;
                font-size: 18px;
              }
            }
            .types-service {
              color: #000;
              display: flex;
              justify-content: space-between;
              margin-top: 4px;
              .type-service-item {
                width: calc(50% - 4px);
                align-items: center;
                padding: 10px 0;
                border: 1px solid var(--primaryColor);
              }
            }
          }
          .item-calendar-doctor {
            background-color: #fff;
            padding: 5px;
            position: relative;
            display: flex;
            &:not(:last-child) {
              margin-bottom: 5px;
            }
            &.is-loading {
              opacity: 0.5;
            }
            .loading-overlay {
              position: absolute;
              top: 50%;
              left: 50%;
              transform: translate(-50%, -50%);
            }
          }
        }
      }
    }

    .list-services {
      display: flex;
      .service-type {
        &.active {
          opacity: 0.5;
        }
      }
    }
  }
}

.timePicker {
  :global {
    .ant-picker-footer {
      display: none;
    }
  }
}

.dropdownSelect {
  :global {
    padding: 0;
  }
}