.input {
  margin-bottom: 0;
  padding: 20px 0;
  text-align: center;
  border: 1px dashed var(--primaryColor);
  cursor: pointer;
}

.preview {
  :global {
    .image-preview {
      display: inline-flex;
      border: 1px solid #d9d9d9;
      margin: 8px 12px 3px 0;
      padding: 3px;
      .inner {
        height: 50px;
        width: 50px;
        position: relative;
        .image {
          width: 100%;
          height: 100%;
          background-size: cover;
          background-position: center;
          display: inline-block;
        }
        .overlay-uploading {
          width: 100%;
          height: 100%;
          display: flex;
          align-items: center;
          justify-content: center;
        }
        .overlay {
          position: absolute;
          top: 0;
          width: 100%;
          height: 100%;
          cursor: pointer;
          i {
            cursor: pointer;
            position: absolute;
            top: 0;
          }
        }
        .icon-delete {
          position: absolute;
          transform: translate(-50%, -50%);
          background: #fff;
          padding: 4px;
          border-radius: 50%;
          &:hover {
            color: #f5222d;
          }
        }
      }
      &.upload-error {
        border-color: #f5222d;
      }
      &.is-selected {
        border-color: var(--primaryColor);
      }
    }
  }
  @media only screen and (min-width: 768px) {
    margin-bottom: 0;
  }
}
.wrapper {
  :global {
    .col {
      padding: 10px;
    }
    .preview-img img {
      max-width: 100%;
    }
    .contentWrap {
      display: flex;
      width: 100%;
      height: 100%;
      .sidebar {
        width: 100%;
        height: 100%;
        max-width: 350px;
        background: #fff;
        padding-left: 10px;
        .title {
          width: 100%;
          padding: 20px;
          box-sizing: border-box;
          border-bottom: 1px solid rgba(255, 255, 255, 0.1);
          color: #333;
          text-transform: uppercase;
          font-weight: 600;
          letter-spacing: .2em;
          font-size: 12px;
        }
        .setting {
          padding: 0 20px;
          margin-top: 20px;
          border-bottom: 1px solid rgba(255, 255, 255, 0.05);
          .filterName {
            font-weight: 600;
            text-transform: uppercase;
            color: #333;
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-pack: justify;
            -ms-flex-pack: justify;
            justify-content: space-between;
            margin-bottom: 5px;
            font-size: 12px;
            // letter-spacing: .2em;
            opacity: .5;
          }
          input[type=range] {
              -webkit-appearance: none;
                  width: 100%;
                margin-bottom: 20px;
            }
            
            input[type=range]::-webkit-slider-runnable-track {
              width: 300px;
              height: 3px;
              background: #333;
              border: none;
            }
            
            input[type=range]::-webkit-slider-thumb {
              -webkit-appearance: none;
              border: none;
              height: 16px;
              width: 16px;
              border-radius: 50%;
              background: #333;
              margin-top: -6px;
            }
            
            input[type=range]:focus {
              outline: none;
            }
            
            input[type=range]:focus::-webkit-slider-runnable-track {
              background: #333;
            }
            
            input[type=range]::-moz-range-track {
              width: 300px;
              height: 3px;
              background: #333;
              border: none;
            }
            
            input[type=range]::-moz-range-thumb {
              border: none;
              height: 16px;
              width: 16px;
              border-radius: 50%;
              background: #333;
            }
            
            input[type=range]:-moz-focusring {
              outline: 1px solid #333;
              outline-offset: -1px;
            }
        }
      }
      .imageContainer {
        width: 50%;
        background: rgba(28, 34, 47, 0.5);
        display: flex;
        -webkit-box-flex: 1;
        flex: 1 0 auto;
        justify-content: center;
        -webkit-box-pack: center;
        align-items: center;
        .guitar{
          height: 90%;
          width: 90%;
          background-repeat: no-repeat;
          background-size: cover;
          position: relative;
          background-position: center;
          box-shadow: 0 10px 20px rgba(0,0,0,0.5);
        
        }
      }
    }
  }
}
