.wrapper-list-booking-lobby {
  display: flex;
  flex-direction: column;
  background-color: #f4f5f7;
  margin-right: 8px;
  transition: all 0.15s ease-in-out;
  min-width: 600px;
  width: 600px;
  transition: all 0.15s ease-in-out;
  &:nth-child(even) {
    .title-list {
      background: #a5ae9d;
    }
  }
  &.is-collapsed {
    width: 350px;
    min-width: 350px;
    max-width: 350px;
    overflow: hidden;
    .booking-service,
    .booking-datetime,
    .booking-checkedtime {
      display: none;
    }
    .booking-username {
      width: 250px !important;
      border-right: none !important;
    }
  }
  .title-list {
    background-color: #bea6a0;
    color: #fff;
    padding: 10px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .select-doctor-room {
      width: 200px;
      display: flex;
      align-items: center;
      &.ant-select-focused {
        .ant-select-selector {
          box-shadow: none !important;
        }
      }
      .ant-select-selector {
        border-radius: 3px;
        display: flex;
        align-items: center;
        border: none !important;
      }
      .ant-select-arrow,
      .ant-select-clear {
        color: var(--primaryColor);
        font-weight: bold;
        background-color: #f1cac0;
        width: 20px;
        height: 20px;
        transform: translateY(-25%);
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 50%;
      }
    }
  }
  .list-booking-lobby {
    .item-booking-lobby {
      box-shadow: #172b4d33 0px 1px 1px, #172b4d33 0px 0px 1px;
      display: flex;
      justify-content: space-between;
      margin-bottom: 1px;
      outline: none;
      position: relative;
      .btn-remove {
        z-index: 3;
        position: absolute;
        right: 0;
        transform: translateX(100%);
        transition: 0.3s ease;
      }
      &:hover {
        .btn-remove {
          position: absolute;
          right: 0;
          transform: translateX(-10px);
          transition: 0.3s ease;
        }
      }
      .actions,
      .booking-numerical,
      .booking-username,
      .booking-service,
      .booking-datetime {
        border-right: 1px solid #bfc3c9;
        padding: 8px;
      }
      .actions-header {
        width: 31px;
        min-width: 31px;
      }
      .booking-numerical {
        min-width: 100px !important;
        position: relative;
        .minutes-waited {
          position: absolute;
          top: 3px;
          right: 3px;
          font-size: 11px;
          width: 40px;
          background-color: #f8e5e0;
          text-align: right;
          border-radius: 3px;
          padding-right: 5px;
          &.impress {
            background-color: var(--primaryColor);
            color: #fff;
          }
        }
      }
      .booking-username {
        width: 200px;
        padding-left: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .required-doctor {
          width: 8px;
          height: 8px;
          background-color: #a5ae9d;
          border-radius: 50%;
          &.wrong-required-doctor {
            background-color: #ffb74d;
          }
        }
      }
      .booking-service {
        width: 25%;
        padding-left: 5px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
      .booking-datetime {
        text-align: right;
        padding-right: 5px;
        min-width: 85px;
      }
      .booking-checkedtime {
        text-align: right;
        min-width: 85px;
        padding: 8px;
        position: relative;
        .type-checked-time {
          position: absolute;
          left: 3px;
          top: 3px;
          font-size: 11px;
          width: 40px;
          background-color: #f8e5e0;
          text-align: left;
          padding-left: 5px;
          border-radius: 3px;
          &.is-ontime {
            background-color: #3cc13b;
          }
          &.is-late {
            background-color: #f03738;
          }
          &.is-soon {
            background-color: #ffb74d;
          }
          &.is-not-booking {
            background-color: #c2c9d1;
          }
        }
      }
    }
  }
}
